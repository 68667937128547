import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'
import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import axios from 'axios'
import qs from 'qs'
import filterLocale from '../components/utils/filter-locale'
import {
	StyledForm,
	HomeButtonRow,
	HomeButton,
} from '../components/form/styles'
import { Formik } from 'formik'
import assign from 'lodash.assign'
import {
	flat,
	validationSwitch,
	validationSchema,
} from '../components/form/utils'
import FormFieldGroup from '../components/form/FormFieldGroup'
import FormFieldSingle from '../components/form/FormFieldSingle'
import PopUp from '../components/modal/forms'

const ContentsSection = tw.section`w-full bg-gray-200`
const ContentsContainer = tw.div`w-95 md:w-11/12 bg-white py-10 px-4 md:px-10 mx-auto border-l border-r border-gray-300 border-b rounded-b-lg mb-16`

const FormContainer = tw.div`flex flex-col items-center justify-center`
const FormTitle = tw.h1`font-display text-5xl md:text-6xl text-gray-700 font-bold text-center mb-3 md:mb-0`
const FormDescription = tw.p`font-display text-lg mb-4 text-gray-700 text-center`
const FormHelp = tw.small`font-display text-base mb-6 md:mb-12 text-gray-700 text-center`
const Reminder = styled.p`
	${tw`font-display py-2 text-black mb-3 text-sm`}

	> a {
		${tw`underline hover:no-underline font-bold`}
	}
`

const ClaimForRimbursment = ({ data: { page, form }, ...props }) => {
	const hs = props.location.hash
	const hash = hs.length && hs[0] == '#' ? hs.replace(/^#/, '') : ''
	const ps = props.location.search
	const params =
		ps.length && ps[0] == '?' ? qs.parse(ps.replace(/^\?/, '')) : ''

	const [showPopUp, setShowPopUp] = React.useState(false)
	const openPopUp = () => setShowPopUp(true)
	const closePopUp = () => setShowPopUp(false)

	const locale = 'en-AU'

	const [{ node }] = filterLocale(page.edges, locale)
	const { seoTitle, seoKeywords, seoDescription } = node

	const [{ node: nodeForm }] = filterLocale(form.edges, locale)
	const {
		title: formTitle,
		description: formDescription,
		items: formItems,
	} = nodeForm

	const valuesMapped = flat(formItems).map(item =>
		item.inputName === 'partsUsed'
			? {
					[item.inputName]:
						'Part No: \nPart Description: \nQuantity: \nSerial No: \n\nPart No: \nPart Description: \nQuantity: \nSerial No: ',
			  }
			: {
					[item.inputName]: '',
			  }
	)
	const validationMapped = flat(formItems).map(item => ({
		[item.inputName]: validationSwitch(item.inputName, item.validationText),
	}))
	/** New Object from mapped Array */
	const valuesObject = assign({}, ...valuesMapped)
	const validationObject = assign({}, ...validationMapped)
	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
			/>
			<ContentsSection id="mum">
				<ContentsContainer>
					<FormContainer>
						<FormTitle>{formTitle}</FormTitle>
						<FormDescription>{formDescription}</FormDescription>
						{/* <FormHelp>
							<b>NOTE</b>: This form is for employees only. If you are a
							customer and <br />
							have any questions, please email customercareau@maytronics.com.au.
							<br />
							Please email any supporting documents to hr.au@maytronics.com
						</FormHelp> */}
						<Formik
							initialValues={valuesObject}
							validationSchema={validationSchema(validationObject)}
							onSubmit={async (values, { setSubmitting, resetForm }) => {
								setSubmitting(true)
								try {
									const result = await axios.post(
										'/.netlify/functions/graphql',
										{
											query: `
											mutation createForm(
												$formName: String!
												$to: String
												$type: String
												$subject: String
												$locale: String
												$company: String
												$name: String
												$phone: String
												$email: String
												$message: String
											) {
												createForm(
													formName: $formName
													to: $to
													type: $type
													subject: $subject
													locale: $locale
													company: $company
													name: $name
													phone: $phone
													email: $email
													message: $message
												) {
													_id
													formName
													to
													type
													subject
													locale
													company
													name
													phone
													email
													message
												}
											}
										`,
											variables: {
												formName: 'water-treatment-claim',
												subject: 'Claim For Rimbursment - Water Treatment',
												to: 'shane.rodger@maytronics.com',
												type: 'multi',
												locale: 'en-AU',
												company: values.company,
												name: values.name,
												phone: values.phone,
												email: values.email,
												message: `<p>G'Day Shane,</p>
<p>${values.name} has submitted a claim for reimbursement of service work undertaken for Water Treatment (Ozone Swim) products.</p>
<p>Claimant Contact Details:</p>
<ul>
	<li><b>Company</b>: ${values.company}</li>
	<li><b>Name</b>: ${values.name}</li>
	<li><b>Phone</b>: ${values.phone}</li>
	<li><b>Email</b>: ${values.email}</li>
</ul>
<p>Product Repaired Details:</p>
<ul>
	<li><b>Product Part No.</b>: ${values.partNo}</li>
	<li><b>Product Serial No.</b>: ${values.serialNo}</li>
	<li><b>Product Description</b>: ${values.productDescription}</li>
</ul>
<p>Fault:</p>
<ul>
	<li>${values.fault}</li>
</ul>
<p>Parts Used:</p>
<ul>
	<li>${values.partsUsed}</li>
</ul>
<p>Other Claims:</p>
<ul>
	<li>${values.otherClaims}</li>
</ul>
<p>Other Comments:</p>
<ul>
	<li>${values.otherComments}</li>
</ul>`,
											},
										}
									)
									console.log(result)
								} catch (error) {
									console.warn(error)
									return ''
								}
								resetForm()
								setSubmitting(false)
								return openPopUp()
							}}
						>
							{({ isSubmitting, errors, touched, values }) => (
								<StyledForm>
									{formItems.map((item, index) => {
										if (item.items) {
											const { title, items } = item
											return (
												<FormFieldGroup
													key={title + index}
													// color="#fff"
													{...{ items, errors, touched, values }}
												/>
											)
										} else {
											return (
												<FormFieldSingle
													key={item.inputName + index}
													overrideWidth="full"
													// color="#fff"
													{...{
														item: { ...item, helpText: null },
														errors,
														touched,
														values,
													}}
												/>
											)
										}
									})}
									<Reminder>
										I wish to receive updates and promotions from Maytronics.
										<br></br>
										For more details, please see our{' '}
										<a
											href="https://maytronics.com.au/privacy"
											target="_blank"
											rel="noopener noreferrer"
										>
											privacy policy.
										</a>{' '}
									</Reminder>
									<HomeButtonRow>
										<HomeButton type="submit" disabled={isSubmitting}>
											Send Message
										</HomeButton>
									</HomeButtonRow>
								</StyledForm>
							)}
						</Formik>
						<PopUp showDialog={showPopUp} close={closePopUp} />
					</FormContainer>
				</ContentsContainer>
			</ContentsSection>
		</Layout>
	)
}

export const ClaimForRimbursmentQuery = graphql`
	query claimForRimbursmentQuery {
		page: allContentfulPage(
			filter: {
				contentful_id: { eq: "3D8bsb0CCsYhLhkCj6U7gz" }
				node_locale: { eq: "en-AU" }
			}
		) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					node_locale
					slug
					body {
						json
					}
				}
			}
		}
		form: allContentfulForm(
			filter: {
				contentful_id: { eq: "2F66IReC7wg16KVxgKrVcN" }
				node_locale: { eq: "en-AU" }
			}
		) {
			edges {
				node {
					title
					description
					endpoint
					node_locale
					items {
						... on ContentfulFormField {
							helpText
							inputAutocomplete
							inputId
							inputName
							inputPlaceholder
							inputRequired
							inputType
							textareaCols
							textareaRows
							icon
							validationText
							width
							options
							node_locale
							label
						}
						... on ContentfulFormGroup {
							title
							items {
								width
								validationText
								textareaRows
								textareaCols
								options
								node_locale
								label
								inputType
								inputRequired
								inputPlaceholder
								inputName
								inputId
								inputAutocomplete
								icon
								helpText
							}
						}
					}
				}
			}
		}
	}
`
ClaimForRimbursment.propTypes = {
	data: PropTypes.objectOf({
		page: PropTypes.arrayOf(PropTypes.object),
		form: PropTypes.arrayOf(PropTypes.object),
	}),
	location: PropTypes.object,
}
export default ClaimForRimbursment
